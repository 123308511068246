import SectionTitle from '@src/components/PageSectionTitle'
import { useTranslation } from 'react-i18next'

const NeedAssistancePage = () => {
  const { t } = useTranslation()

  return (
    <main className="step-0">
      <SectionTitle title={t('identification.assistanceRequested')} />
      <h1>{t('identification.agentContact')}</h1>
    </main>
  )
}

export default NeedAssistancePage
