import {
  ApplicantJob,
  ApplicantOtherIncome,
  Expenses,
  Applicant,
  Prequalification,
} from '@src/types/CreditApplicationSchema'
import { EHomeFeeType, EJobType } from '@src/types'
import { FilteredCreditApplication, RequiredDocument } from './types/FilteredCreditApplication'

export function transformApplicantExpensesToApi(expenses: Expenses): Expenses {
  let ret = { ...expenses }

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(ret)) {
    if (!value && value !== 0) {
      ret = { ...expenses, [key]: null }
    }
  }

  if (ret.homeFeeTypeId === EHomeFeeType.OwnWithMortgage) {
    ret.mortgageMonthly = expenses.housingMonthly
    ret.rentMonthly = null
  } else {
    ret.rentMonthly = expenses.housingMonthly
    ret.mortgageMonthly = null
  }

  return ret
}

function transformOtherIncomes(other: ApplicantOtherIncome[]): ApplicantOtherIncome[] {
  if (other.length > 0) return other.filter((x) => x.typeId)
  return other
}

function transformCurrentJobs(jobs: ApplicantJob[]): ApplicantJob[] {
  if (jobs.length > 0) return jobs.filter((x) => x.jobType !== null && x.jobType !== EJobType.Unemployed)
  return jobs
}

export function transformApplicantToApi(applicant: Applicant) {
  return {
    ...applicant,
    expenses: transformApplicantExpensesToApi(applicant.expenses),
    otherIncomes: transformOtherIncomes(applicant.otherIncomes),
    currentJobs: transformCurrentJobs(applicant.currentJobs),
  } as Applicant
}
export function transformPrequalToApi(prequal: Prequalification): Prequalification {
  const toPrequalifyCreditApplication = {
    ...prequal,
    applicant: transformApplicantToApi(prequal.applicant),
  } as Prequalification

  return toPrequalifyCreditApplication
}

function transformRequiredDocumentsFromApi(requiredDocuments: RequiredDocument[]): RequiredDocument[] {
  const transformedRequiredDocuments = requiredDocuments.map((doc: RequiredDocument) => ({
    ...doc,
    nameFr: doc.nameFr,
    nameEn: doc.nameEn,
  }))
  return transformedRequiredDocuments
}

export function transformCreditApplicationFromApi(creditApp: FilteredCreditApplication): FilteredCreditApplication {
  return {
    ...creditApp,
    requiredDocuments: transformRequiredDocumentsFromApi(creditApp.requiredDocuments),
  }
}
