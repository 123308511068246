import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const FORM_ID = 'generic-input'

type Props = {
  currentStepIndex: number
  disableBack: boolean
  stepLength: number
  disableNext: boolean | undefined
  setDisableNext: (disable: boolean) => void
}
const StepperFormFooter = ({ currentStepIndex, stepLength, disableBack, disableNext, setDisableNext }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goBack = useCallback(() => {
    navigate(-1)
    setDisableNext(false)
  }, [navigate, setDisableNext])

  return (
    <footer className="form-footer">
      <button disabled={disableBack} type="button" onClick={goBack} className="btn btn-grey">
        <i className="fa-light fa-arrow-left" />
        <span>{t('common.back')}</span>
      </button>
      <div className="step-count">
        <span>
          {currentStepIndex}&nbsp;/&nbsp;{stepLength}
        </span>
      </div>

      <button type="submit" className="btn btn-blue btn-simple" disabled={disableNext} form={FORM_ID}>
        <span>{t('common.next')}</span>
        <i className="fa-regular fa-arrow-right" />
      </button>
    </footer>
  )
}

export default StepperFormFooter
