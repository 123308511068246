import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useTranslation } from 'react-i18next'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'
import SectionTitle from '@src/components/PageSectionTitle'

type Props = {
  creditApp: FilteredCreditApplication
}

const DeclinedPrequalForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  useTrackStepReachedEvent(creditApp, ETrackedEvents.PrequalDeclined)

  return (
    <main className="general-message">
      <SectionTitle title={t('common.financeApplication')} />
      <h3>{t('common.refused', { forStep: t('common.prequalified') })}</h3>
    </main>
  )
}

export default DeclinedPrequalForm
