import { MutationFunction, QueryFunctionContext, useMutation, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'identity-verification-session'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

export type IdentityVerificationSession = {
  id: string
  token: string
}

const getIdentityVerificationSession = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  try {
    const response = await apiClient.get<IdentityVerificationSession>(
      `/api/creditApplication/${encodeURIComponent(id)}/IdentityVerificationSession`,
    )
    return response.data
  } catch (e) {
    return null
  }
}

export function useGetIdentityVerificationSession(
  creditApplicationId: string,
): [IdentityVerificationSession | null, boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getIdentityVerificationSession,
    enabled: !!creditApplicationId,
  })

  return [data ?? null, isFetching]
}

const updateIdentityVerificationSession: MutationFunction<
  IdentityVerificationSession,
  IdentityVerificationSession
> = async ({ id, token: sessionToken }: IdentityVerificationSession) => {
  const response = await apiClient.put(`/api/creditapplication/${encodeURIComponent(id)}/IdentityVerificationSession`, {
    token: sessionToken,
  })
  return response.data as IdentityVerificationSession
}

export function useUpdateIdentityVerificationSession(): [
  MutationFunction<IdentityVerificationSession, IdentityVerificationSession>,
  boolean,
  () => void,
] {
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateIdentityVerificationSession,
  })

  return [mutateAsync, isPending, reset]
}
