import { useEffect } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useTranslation } from 'react-i18next'
import { ETrackedEvents, trackEvent } from '@src/services/trackingEvents'
import SectionTitle from '@src/components/PageSectionTitle'

type Props = {
  creditApp: FilteredCreditApplication
}

const DeclinedDecisionForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
    trackEvent(ETrackedEvents.QualifDeclined, creditApp)
  }, [creditApp])

  return (
    <main className="form-section" style={{ textAlign: 'center' }}>
      <SectionTitle title={t('common.financeApplication')} />
      <p>
        {t(`finalDecision.refused`, {
          forStep: t('common.qualified'),
        })}
      </p>
    </main>
  )
}

export default DeclinedDecisionForm
