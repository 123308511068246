import SectionTitle from '@src/components/PageSectionTitle'
import { useTranslation } from 'react-i18next'

const UnderReviewTile = () => {
  const { t } = useTranslation()

  return (
    <main className="general-message">
      <div>
        <SectionTitle title={t('common.AnalysisInProgress')} />
        <h3>{t('common.hasBeenSubmitted')}</h3>
      </div>
    </main>
  )
}

export default UnderReviewTile
