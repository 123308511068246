import SectionTitle from '@src/components/PageSectionTitle'
import useFaqLink from '@src/hooks/useFaqLink'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

const WelcomePage = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const faqLink = useFaqLink()

  return (
    <main className="step-0">
      <SectionTitle title={t('welcome.applyInMinutes')} />
      <h1>{t('welcome.prequalifiedRapidly')}</h1>
      <div className="paragraph">
        <p>{t('welcome.answerQuestions')}</p>
      </div>
      <div id="flinks-steps-group">
        <div id="flinks-step" className="applicationInfo-card">
          <p className="paragraph" style={{ margin: 0, maxWidth: 'none' }}>
            {t('welcome.yourself')}
          </p>
          <i className="fa-regular fa-address-card" />
        </div>
        <div id="flinks-step" className="applicationInfo-card">
          <p className="paragraph" style={{ margin: 0, maxWidth: 'none' }}>
            {t('welcome.validateIcome')}
          </p>
          <i className="fa-regular fa-memo-circle-check" />
        </div>
        <div id="flinks-step" className="applicationInfo-card">
          <p className="paragraph" style={{ margin: 0, maxWidth: 'none' }}>
            {t('welcome.selectPlan')}
          </p>
          <i className="fa-regular fa-hand-holding-circle-dollar" />
        </div>
      </div>
      <button
        id="btnBegin"
        type="button"
        onClick={() => navigate(`/eligibility`, { replace: true })}
        className="btn btn-blue"
      >
        {t('welcome.getStarted')}
        <i className="fa-regular fa-arrow-right" />
      </button>
      <p style={{ fontSize: '1.5rem', marginTop: '1rem' }}>
        <Trans
          i18nKey="welcome.question"
          components={{
            site_anchor: <Link to={faqLink} target="_blank" />,
          }}
        />
      </p>
    </main>
  )
}

export default WelcomePage
