import { useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import {
  applicantBankAccountStatusMatch,
  applicantIncomeAccepted,
  areAllDocumentsReadyForCVT,
  hasApplicantCompletedFlinks,
} from '@src/data/creditapp-selectors'
import { ERequiredExternalStepStatus } from '@src/types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const FlinsStatusPage = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const id = params.id as string
  const navigate = useNavigate()
  const [shouldPoll, setShouldPoll] = useState(true)
  const [progression, setProgression] = useState<number>(1)
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id, shouldPoll)

  const flinksAccepted = applicantIncomeAccepted(creditApp)
  const flinksCompleted = hasApplicantCompletedFlinks(creditApp)

  const requiredDocumentsWithStatus = useRequiredDocumentsWithStatus(creditApp?.requiredDocuments, creditApp?.documents)

  const allDocumentsReadyForCVT = areAllDocumentsReadyForCVT(requiredDocumentsWithStatus)

  const canSpecifyLoanDetails = allDocumentsReadyForCVT && creditApp?.applicant.areIncomesConfirmed

  useEffect(() => {
    const interval = setInterval(() => {
      if (flinksCompleted) {
        setProgression(100)
        setShouldPoll(false)
      }

      if (progression && progression < 100) {
        setProgression((p) => (p ?? 0) + 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [creditApp, flinksCompleted, progression])

  if (flinksCompleted && !flinksAccepted) {
    navigate(`/creditapplication/${id}/incomes`, { replace: true })
  }

  const [verifiedTitle, verifiedDescription] = useMemo((): string[] => {
    const verified: string = t('flinks.incomeVerified')
    const completeLoan: string = t('flinks.completeTheLoan')
    const requiredDocuments: string = t('flinks.requiredDocuments')
    const manualReview: string = t('flinks.manualReview')

    if (canSpecifyLoanDetails) return [t('common.goodNews'), `${verified} ${completeLoan}`]

    if (!allDocumentsReadyForCVT) return [t('common.thankYou'), `${verified} ${requiredDocuments}`]

    return [t('common.thankYou'), `${verified} ${manualReview}`]
  }, [allDocumentsReadyForCVT, canSpecifyLoanDetails, t])

  if (isLoadingApp || (progression && progression < 100 && shouldPoll)) {
    let title: string = t('common.withYouShortly')
    let description: string = t('common.pleaseWait')

    if (progression && applicantBankAccountStatusMatch(creditApp, ERequiredExternalStepStatus.WaitingForBank)) {
      title = t('flinks.accountRetrieved')
      description = t('flinks.processingInfomation')
    } else if (
      progression &&
      applicantBankAccountStatusMatch(creditApp, ERequiredExternalStepStatus.AnalysisInProgress)
    ) {
      title = t('flinks.incomeAnalysis')
      description = t('flinks.bankStementAnalysed')
    }

    return <Loader title={title} description={description} progression={progression} />
  }

  const handleContinue = () => {
    let url = `/creditapplication/${id}`

    if (canSpecifyLoanDetails) url += '/worksheet'
    else if (!allDocumentsReadyForCVT) url += '/required-documents'

    navigate(url, { replace: true })
  }

  return (
    <main className="general-message" style={{ display: 'block', textAlign: 'center' }}>
      <h1 style={{ marginBottom: '1rem' }}>{verifiedTitle}</h1>
      <h3>{verifiedDescription}</h3>
      <button type="submit" className="btn primary-action-btn btn-blue btn-simple" onClick={handleContinue}>
        <span>{t('common.continue')}</span>
        <i className="fa-regular fa-arrow-right" />
      </button>
    </main>
  )
}

export default FlinsStatusPage
