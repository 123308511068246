import { areAllDocumentsApproved, hasMissingDocuments } from '@src/data/creditapp-selectors'
import { useTranslation } from 'react-i18next'
import { useLayoutEffect } from 'react'
import { scrollToTop } from '@src/services/utils'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import CreditStep, { CreditStepStatus } from './CreditStep'

type DocumentsCreditStepProps = {
  creditApp: FilteredCreditApplication
}
const DocumentsCreditStep = ({ creditApp }: DocumentsCreditStepProps) => {
  const { t } = useTranslation()
  const allDocsAreApproved = areAllDocumentsApproved(creditApp)
  const isMissingDocs = hasMissingDocuments(creditApp)
  const status: CreditStepStatus = isMissingDocs ? CreditStepStatus.RequiresAttention : CreditStepStatus.Completed
  let desc
  if (isMissingDocs) desc = 'overview.moreDocs' as const
  else if (allDocsAreApproved) desc = 'overview.noDocuments' as const
  else desc = 'overview.documentReview' as const

  useLayoutEffect(scrollToTop, [])
  return (
    <CreditStep
      status={status}
      linkToDetail="required-documents"
      hideLinkToDetail={allDocsAreApproved}
      title={t('overview.documentsStep')}
      content={t(desc)}
    />
  )
}

export default DocumentsCreditStep
