import { useMemo } from 'react'
import { RadioButtonListOption } from '@src/components/RadioButtonList'
import { FormatCurrency } from '@src/services/Formatter'
import { Trans, useTranslation } from 'react-i18next'
import { MonthlyPayment } from '@src/api/compute-api'

export default function useTermAmountOptions(monthlyPaymentPerMonths: MonthlyPayment[]): RadioButtonListOption[] {
  const { t } = useTranslation()
  return useMemo(() => {
    const ret: RadioButtonListOption[] = []
    monthlyPaymentPerMonths.forEach((term) => {
      const value: RadioButtonListOption = {
        id: term.term.toString(),
        primaryText: t('loan.loanTermMonths', {
          loanTerm: term.term,
        }),
        secondaryText: (
          <Trans
            i18nKey="loan.estimatedPayments"
            values={{ loanAmount: FormatCurrency(term.amountWithoutInsurance) }}
          />
        ),
      }
      ret.push(value)
    })
    return ret
  }, [monthlyPaymentPerMonths, t])
}
