import * as yup from '@src/types/schemas/common/yup-extended'
import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import { useMemo } from 'react'
import { Constants } from '@src/types'

function buildWorksheetSchema(availableCredit: number, amountRequested: number, term: number) {
  return yup.default.object({
    amountRequested: yupExtInt.double.required().default(amountRequested).min(500).max(availableCredit),
    term: yupExtInt.integer.required().default(term),
    firstPaymentOn: yup.default.string().isValidDate().required(),
    includeInsurance: yup.default.boolean().required(),
  })
}
export function useWorksheetSchema(availableCredit: number, amountRequested: number, term: number) {
  return useMemo(
    () => buildWorksheetSchema(availableCredit, amountRequested, term),
    [availableCredit, amountRequested, term],
  )
}

const defaultSchema = buildWorksheetSchema(Constants.MinLoanAmount, 500, 72)
export type WorksheetForm = yup.default.InferType<typeof defaultSchema>
