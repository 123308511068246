import ReactPixel from 'react-facebook-pixel'

export default function initializeFPixel(): void {
  const FPIXEL_MEASURE_ID = process.env.REACT_APP_B2C_FPIXEL_MEASURE_ID

  if (!FPIXEL_MEASURE_ID) {
    console.error('FPixel initialization failed: Invalid or missing measure ID')
    return
  }

  ReactPixel.init(FPIXEL_MEASURE_ID)
  ReactPixel.pageView()
}
