import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { applicantFlinksId } from '@src/data/creditapp-selectors'
import Loader from '@src/components/Loader'
import { EFlinksEventStep } from '@src/types'
import {
  CompleteBankAccountRequestDto,
  useCompleteApplicantFlinks,
  useGetCreditApplicationById,
} from '@src/api/credit-api'
import { useNavigate, useParams } from 'react-router-dom'
import { reportErrorToConsole } from '@src/services/error-logger'

const FlinksIncomeValidationPage = (): JSX.Element => {
  const { t, i18n } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const id = params.id as string
  const [completeFlinks, isCompletingFlinks] = useCompleteApplicantFlinks()
  const [creditApp, isLoadingApp] = useGetCreditApplicationById(id, false)
  const externalServiceId = applicantFlinksId(creditApp)
  const { innerHeight: height } = window

  useEffect(() => {
    window.addEventListener('message', (e: MessageEvent<{ step?: EFlinksEventStep }>) => {
      if (e.data?.step) {
        // The request is done, we are receiving the answer
        if (e.data.step === EFlinksEventStep.REDIRECT) {
          const completeFlinksDto = {
            id,
            externalServiceId,
          } as CompleteBankAccountRequestDto

          completeFlinks(completeFlinksDto).catch(reportErrorToConsole)

          navigate(`/creditapplication/${id}/flinks/status`, { replace: true })
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoadingApp) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  const iframeUrl =
    process.env.REACT_APP_FLINKS_IFRAME +
    `?theme=light
  &desktopLayout=true
  &customerName=IFinance
  &institutionFilterEnable=true
  &demo=${process.env.REACT_APP_USE_DEMO_FLINKS ?? 'false'}
  &consentEnable=true
  &accountSelectorEnable=true
  &daysOfTransactions=Days365
  &monthsOfStatements=Months12
  &enhancedMFA=true
  &staticLoadingEnable=true
  &tag=${process.env.REACT_APP_ENV}_${externalServiceId}
  &language=${i18n.language}
  &scheduleRefresh=false`
      .replace(/ /g, '')
      .replace(/(\r\n|\n|\r)/gm, '')

  return (
    <main id="flinks-validation" className="general-message">
      <iframe title="flinksIFrame" height={`${height - 100} px`} width="100%" loading="eager" src={iframeUrl} />
      {isCompletingFlinks && <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />}
    </main>
  )
}

export default FlinksIncomeValidationPage
