import { createContext, useContext, useMemo, useState } from 'react'
import { OriginationTracking } from '@src/types/origination'

export type TAppStore = {
  origination: OriginationTracking | null
  setOrigination: (origination: OriginationTracking) => void
}

export const AppContext = createContext<TAppStore | null>(null)

export function useAppStoreProvider(initialOrigination: OriginationTracking | null) {
  const [origination, setOrigination] = useState<OriginationTracking | null>(initialOrigination)

  return useMemo(
    () => ({
      origination,
      setOrigination,
    }),
    [origination, setOrigination],
  )
}

export function useAppStore(): TAppStore {
  return useContext(AppContext)!
}
