/* eslint-disable func-names */
import { isValid, parseISO } from 'date-fns'
import * as yup from 'yup'
import { setLocale } from 'yup'
import { findMinimumAgeRequirement } from '@src/data/creditapp-selectors'

import i18n from 'i18next'
import { EProvince } from '@src/types/constants'
/**
 * Validate a Canadian Social Insurance Number (SIN)
 * @param  {string} sinText - A 9-digit Canadian SIN
 * @return {bool} - Validity of the input SIN
 */
function isValidSin(sinText: string) {
  const trimmedSin = sinText.trim().replace(/ /g, '')

  if (trimmedSin.length === 9) {
    // convert to an array & pop off the check digit
    const sin: string[] = trimmedSin.split('')
    const check = parseInt(sin.pop() as string, 10)

    const even = sin
      // take the digits at the even indices
      .filter((_, i: number) => {
        return i % 2
      })
      // multiply them by two
      .map((n: string) => {
        return parseInt(n, 10) * 2
      })
      // and split them into individual digits
      .join('')
      .split('')

    const tot = sin
      // take the digits at the odd indices
      .filter((_, i: number) => {
        return !(i % 2)
      })
      // concatenate them with the transformed numbers above
      .concat(even)
      // it's currently an array of strings; we want numbers
      .map((n: string) => {
        return parseInt(n, 10)
      })
      // and take the sum
      .reduce((acc: number, cur: number) => {
        return acc + cur
      })

    // compare the result against the check digit
    return check === (10 - (tot % 10)) % 10
  }
  return false
}

function isValidPercent(value: string | number | null) {
  if (value !== null) {
    const dot = value.toString().replace(',', '.').indexOf('.')
    const decimal = value.toString().substring(dot)
    if (dot === -1) return true
    if (decimal.length <= 3) return true
  }
  return false
}

yup.addMethod(yup.number, 'maxReference', function (refPath: string, message: string) {
  return this.test('maxReference', message, function (value, context) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const refValue = context.parent[refPath] as number

    if (value === undefined || value > refValue) {
      return context.createError({
        path: context.path,
        message: message || `${context.path} must be less than or equal to ${refPath}`,
      })
    }
    return true
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'IsNotEmpty', function (errorMessage: string) {
  return this.test('test-NotEmpty', errorMessage, function (value) {
    const { path, createError } = this

    if (value) return value.trim().length > 0 || createError({ path, message: 'common.errors.required' })
    return true
  })
})

yup.addMethod(yup.string, 'defaultString', function (defaultValue: string) {
  return this.transform((value, originalValue) => {
    return originalValue == null || (typeof originalValue === 'string' && originalValue.trim() === '')
      ? defaultValue
      : (value as string)
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'SinType', function (errorMessage: string) {
  return this.test('test-Sin-type', errorMessage, function (value) {
    const { path, createError } = this

    return !value || isValidSin(value) || createError({ path, message: errorMessage })
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'IsValidCanadianPostalCode', function (errorMessage: string) {
  return this.test('test-PostalCode-type', errorMessage, function (value) {
    const { path, createError } = this
    const exp = '^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$'
    if (value) {
      return new RegExp(exp).test(value.toUpperCase()) || createError({ path, message: 'common.errors.postalCode' })
    }
    return true
  })
})

yup.addMethod<yup.NumberSchema>(yup.number, 'PercentType', function (errorMessage: string) {
  return this.test('test-Percent-type', errorMessage, function (value) {
    const { path, createError } = this

    return !value || isValidPercent(value) || createError({ path, message: 'common.errors.percentError' })
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'IsValidCanadianPhone', function (errorMessage: string) {
  return this.test('test-CellPhone-type', errorMessage, function (value) {
    const { path, createError } = this
    const exp = '^[2-9]{1}[0-9]{2}?[-]?[0-9]{3}?[-]?[0-9]{4}$'
    if (value) {
      return new RegExp(exp).test(value) || createError({ path, message: 'common.errors.phone' })
    }
    return true
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'IsValidPhoneExtension', function (errorMessage: string) {
  return this.test('test-PhoneExt-type', errorMessage, function (value) {
    const { path, createError } = this
    const exp = '^[0-9]{1,10}$'
    if (value) {
      return new RegExp(exp).test(value) || createError({ path, message: 'common.errors.extension' })
    }
    return true
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'isValidDate', function (errorMessage: string) {
  return this.test('test-ValideDate', errorMessage, function (value) {
    const { path, createError } = this

    if (value) return isValid(parseISO(value)) || createError({ path, message: 'common.errors.date' })

    return true
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'IsNotEmpty', function (errorMessage: string) {
  return this.test('test-NotEmpty', errorMessage, function (value) {
    const { path, createError } = this

    if (value) return value.trim().length > 0 || createError({ path, message: 'common.errors.required' })
    return true
  })
})
yup.addMethod<yup.StringSchema>(yup.string, 'IsValidCanadianPhone', function (errorMessage: string) {
  return this.test('test-CellPhone-type', errorMessage, function (value) {
    const { path, createError } = this
    const exp = '^[0-9]{3}?[-]?[0-9]{3}?[-]?[0-9]{4}'
    if (value) {
      return new RegExp(exp).test(value) || createError({ path, message: 'common.errors.phone' })
    }
    return true
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'EmptyStringToNull', function () {
  return this.transform(function (value: string | null | undefined) {
    if (value?.trim()) return value.trim()
    return null
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'validateAgeRequirement', function (errorMessage: string) {
  return this.test('test-ValideDate', errorMessage, function (value) {
    const { path, createError } = this

    const stateIso = (this.parent as { currentAddress: { stateIso: EProvince } }).currentAddress.stateIso
    const minAge = findMinimumAgeRequirement(stateIso)
    const today = new Date()
    const minimumDate = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
    if (value) {
      const birthDate = new Date(value)
      return birthDate <= minimumDate || createError({ path, message: 'common.errors.minimumAgeRequired' })
    }

    return true
  })
})

yup.addMethod<yup.NumberSchema>(yup.number, 'isRequiredNumber', function (errorMessage: string) {
  return this.test('test-requiredInteger', errorMessage, function (value) {
    const { path, createError } = this
    if (value === null || value === undefined) return createError({ path, message: 'common.errors.required' })
    return true
  })
})

declare module 'yup' {
  interface StringSchema<
    TType extends yup.Maybe<string> = string | undefined,
    TContext extends yup.AnyObject = yup.AnyObject,
  > extends yup.Schema<TType, TContext> {
    SinType(): StringSchema
    IsValidCanadianPostalCode(): StringSchema
    IsValidCanadianPhone(): StringSchema
    isValidDate(): StringSchema
    IsNotEmpty(): StringSchema
    IsValidPhoneExtension(): StringSchema
    IsValidCanadianPhone(): StringSchema<TType, TContext>
    validateAgeRequirement(): StringSchema<TType, TContext>
    EmptyStringToNull(): StringSchema<TType, TContext>
  }
  interface NumberSchema {
    PercentType(): NumberSchema
    isRequiredNumber(): NumberSchema
    maxReference(refPath: string, message?: string): this
  }
}
export default yup

/**
 * Add basic error message
 */
setLocale({
  number: {
    min: ({ min }) => i18n.t('common.errors.greaterOrEqual_other', { min }),
    max: ({ max }) => i18n.t('common.errors.lowerOrEqual_other', { max }),
    positive: 'common.errors.positive',
  },
  string: {
    min: ({ min }) => i18n.t('common.errors.greaterOrEqualChar_other', { min }),
    max: ({ max }) => i18n.t('common.errors.lowerOrEqualChar_other', { max }),
  },
})
