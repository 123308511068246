import { hasMissingDocuments } from '@src/data/creditapp-selectors'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { EApplicationStep, EDecision } from '@src/types'
import { Loader } from '@src/components'
import { useTranslation } from 'react-i18next'
import DecisionningErrorTile from '../components/DecisionningErrorTile'
import DeclinedDecisionForm from '../components/DeclinedDecisionForm'
import UnderReviewTile from '../components/UnderReviewTile'
import CreditFileLockedTile from './components/CreditFileLockedTile'
import RequiredDocForDecisionForm from './components/RequiredDocForDecisionForm'

type Props = {
  creditApp: FilteredCreditApplication
}

const HardHitUnapprovedResultPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  const hasMissingDocForCreditDecision = hasMissingDocuments(creditApp, EApplicationStep.Credit)

  if (creditApp.applicant.hasLockedCreditReport) return <CreditFileLockedTile creditApp={creditApp} />

  if (creditApp.finalDecision.decision === EDecision.Error || !creditApp.applicant.hasCreditReport)
    return <DecisionningErrorTile />

  if (creditApp.finalDecision.decision === EDecision.Refused) return <DeclinedDecisionForm creditApp={creditApp} />

  if (creditApp.finalDecision.decision === EDecision.ManualRevision && !hasMissingDocForCreditDecision)
    return <UnderReviewTile />

  if (creditApp.finalDecision.decision === EDecision.ManualRevision && hasMissingDocForCreditDecision)
    return <RequiredDocForDecisionForm creditApp={creditApp} />

  return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
}

export default HardHitUnapprovedResultPage
