import SectionTitle from '@src/components/PageSectionTitle'
import { useTranslation } from 'react-i18next'

const DecisionningErrorTile = () => {
  const { t } = useTranslation()

  return (
    <main className="general-message">
      <SectionTitle title={t('common.AnalysisInProgress')} />
      <h3>{t('common.errorObtainingCreditReport')}</h3>
    </main>
  )
}

export default DecisionningErrorTile
