import { CustomerFeedback } from '@src/api/feedback-api'
import { EContractStatus } from '@src/types'
import {
  hasApplicantCompletedFlinks,
  hasApplicantSubmittedAllRequiredIncomeDocuments,
  hasApplicantSubmittedFlinks,
} from '@src/data/creditapp-selectors'
import { FilteredCreditApplication, RequiredDocumentWithStatus } from '@src/api/types/FilteredCreditApplication'
import { CreditStepStatus } from './OverviewPage/components/CreditStep'

const contractIsReceivedStatusSet = new Set([
  EContractStatus.UnderReview,
  EContractStatus.Completed,
  EContractStatus.Declined,
])

export function contractIsRecerved(status: EContractStatus) {
  return contractIsReceivedStatusSet.has(status)
}

export function isReadyForCustomerFeedback(
  creditApp: FilteredCreditApplication | null,
  requiredDocuments: RequiredDocumentWithStatus[],
): boolean {
  if (!creditApp) return false
  return (
    creditApp.allIncomesConfirmed ||
    hasApplicantCompletedFlinks(creditApp) ||
    hasApplicantSubmittedFlinks(creditApp) ||
    hasApplicantSubmittedAllRequiredIncomeDocuments(requiredDocuments)
  )
}

export function getFeedbackStepStatus(
  creditApp: FilteredCreditApplication | null,
  requiredDocuments: RequiredDocumentWithStatus[],
  feedback: CustomerFeedback | null,
) {
  if (!isReadyForCustomerFeedback(creditApp, requiredDocuments)) return CreditStepStatus.NotReady
  if (!feedback) return CreditStepStatus.RequiresAttention
  return CreditStepStatus.Completed
}
