import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import SectionTitle from '@src/components/PageSectionTitle'
import { useTranslation } from 'react-i18next'

type Props = {
  creditApp: FilteredCreditApplication
}
const CompletedAppPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const msg = creditApp.loanCreated ? 'common.loanCreated' : 'common.awaitingContractReview'
  return (
    <main className="general-message">
      <SectionTitle title={t('common.goodNews')} />
      <div>
        <h3>{t(msg)}</h3>
      </div>
    </main>
  )
}

export default CompletedAppPage
