import { useTranslation } from 'react-i18next'
import { applicantFullName } from '@src/data/creditapp-selectors'
import { replaceWithNonBreakingHyphen } from '@src/services/utils'
import { FilteredCreditApplication, RequiredDocumentWithStatus } from '@src/api/types/FilteredCreditApplication'
import { useGetFeedback } from '@src/api/feedback-api'
import Loader from '@src/components/Loader'
import DocumentsCreditStep from './components/DocumentsCreditStep'
import CreditStep, { CreditStepStatus } from './components/CreditStep'
import IncomeCreditStep from './components/IncomeCreditStep'
import { getFeedbackStepStatus } from '../hooks'
import WorksheetCreditStep from './components/WorksheetCreditStep'

type Props = {
  creditApp: FilteredCreditApplication
  requiredDocuments: RequiredDocumentWithStatus[]
}

const OverviewPage = ({ creditApp, requiredDocuments }: Props) => {
  const { t } = useTranslation()
  const [feedback, isLoadingFeedback] = useGetFeedback(creditApp.id)
  const feedbackStepStatus = getFeedbackStepStatus(creditApp, requiredDocuments, feedback)

  if (isLoadingFeedback) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return (
    <div className="container container-centered">
      <div className="limited-width-container">
        <div className="credit-steps">
          <h3>{t('common.appNumber', { number: creditApp.referenceNumber })}</h3>
          <span style={{ textAlign: 'center' }}>
            {replaceWithNonBreakingHyphen(applicantFullName(creditApp.applicant))}
          </span>
        </div>
        <div className="credit-steps" style={{ marginTop: '2rem' }}>
          <CreditStep
            status={CreditStepStatus.Completed}
            linkToDetail="credit-summary"
            noConnector
            title={t('overview.creditStep')}
            content={t('overview.creditStepActions')}
          />
          <DocumentsCreditStep creditApp={creditApp} />
          <IncomeCreditStep creditApp={creditApp} />
          <WorksheetCreditStep creditApp={creditApp} />
          <CreditStep
            status={feedbackStepStatus}
            linkToDetail="feed-back"
            title={t('overview.rateExperienceStep')}
            content={t('overview.rateExperienceContent')}
            hideLinkToDetail={feedbackStepStatus !== CreditStepStatus.RequiresAttention}
          />
        </div>
      </div>
    </div>
  )
}

export default OverviewPage
