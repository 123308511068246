import { Loader } from '@src/components'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  IdentityVerificationSession,
  useGetIdentityVerificationSession,
} from '@src/api/identity-verification-session-api'
import VouchedComponent from './VouchedComponent'

type Props = {
  creditApp: FilteredCreditApplication
}

const VouchedPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [session, isLoading] = useGetIdentityVerificationSession(id as string)

  return (
    <div style={{ height: '90%' }}>
      {isLoading ? (
        <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
      ) : (
        <div>
          <VouchedComponent
            id="vouched-element"
            session={session ?? ({ id: creditApp.id } as IdentityVerificationSession)}
            creditApplication={creditApp}
          />
        </div>
      )}
    </div>
  )
}

export default VouchedPage
