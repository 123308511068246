import { useEffect, useMemo } from 'react'
import { RadioButtonListOption } from '@src/components/RadioButtonList'
import { formatDate } from '@src/services/Formatter'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { UseFormTrigger } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { reportErrorToConsole } from '@src/services/error-logger'
import { computeFirstPaymentDateOptions } from './selectors'
import { WorksheetForm } from './schema-hooks'

export enum EWorksheetStep {
  AmountRequested = 'amount-requested',
  FirstPayment = 'first-payment',
  PayOverTime = 'pay-over-time',
  ProtectionPlan = 'protection-plan',
  Summary = 'summary',
}

export const EWorksheetStepList = Object.values(EWorksheetStep)

export const DEFAULT_STEP = EWorksheetStep.AmountRequested

export const useComputeFirstPaymentDateOptions = (activationDate: string, listHolidays: Date[]) => {
  const { t } = useTranslation()
  return useMemo(() => {
    const dateList = computeFirstPaymentDateOptions(activationDate, listHolidays)

    const dateListString: RadioButtonListOption[] = dateList.map<RadioButtonListOption>((date, index, arr) => {
      const option: RadioButtonListOption = {
        id: formatDate(date),
        primaryText: format(date, 'PPPP'),
      }
      if (index === arr.length - 1) option.secondaryText = t('worksheet.firstPayment.nextMonthPromo')
      return option
    })

    return dateListString
  }, [activationDate, listHolidays, t])
}

type InputName = keyof WorksheetForm

const InputsByStepMapping: Record<EWorksheetStep, InputName[]> = {
  [EWorksheetStep.AmountRequested]: ['amountRequested'],
  [EWorksheetStep.FirstPayment]: ['firstPaymentOn'],
  [EWorksheetStep.PayOverTime]: ['term'],
  [EWorksheetStep.ProtectionPlan]: ['includeInsurance'],
  [EWorksheetStep.Summary]: [],
}

export const useWorksheetStepIsValid = (
  currentStep: string,
  steps: string[],
  trigger: UseFormTrigger<WorksheetForm>,
) => {
  const navigate = useNavigate()
  const inputListToValidate = useMemo<InputName[]>(() => {
    if (!steps.length) return []
    const inputNameList: InputName[] = []
    const currentStepIndex = steps.indexOf(currentStep)
    for (let i = 0; i < currentStepIndex; i += 1) {
      inputNameList.push(...InputsByStepMapping[steps[i] as EWorksheetStep])
    }

    return inputNameList
  }, [currentStep, steps])

  useEffect(() => {
    trigger(inputListToValidate)
      .then((isValid) => {
        if (!isValid) navigate(`../worksheet/${DEFAULT_STEP}`, { replace: true })
      })
      .catch(reportErrorToConsole)
  }, [inputListToValidate, navigate, trigger])
}
