import {
  FormatCurrencyToNearestLowerMultipleToNoDecimals,
  FormatCurrencyToNearestUpperMultipleToNoDecimals,
} from '@src/services/Formatter'

import { useTranslation } from 'react-i18next'

type Props = {
  min: number
  max: number
}

const MonthlyPaymentRange = ({ min, max }: Props) => {
  const { t } = useTranslation()
  const msg = t('prequalification.modifiedLoanMessage', {
    minMonthlyPayment: FormatCurrencyToNearestLowerMultipleToNoDecimals(min, 5),
    maxMonthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(max, 5),
  })

  return <p style={{ fontStyle: 'italic' }}>{msg}</p>
}

export default MonthlyPaymentRange
