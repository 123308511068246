import { useTranslation } from 'react-i18next'
import BackButton from '@src/components/BackButton'
import ConsentEn from './ConsentEn'
import ConsentFr from './ConsentFr'

const TermsAndConditionsFlinksPage = (): JSX.Element => {
  const { i18n } = useTranslation()

  return (
    <main className="general-message">
      {i18n.language === 'fr' && <ConsentFr />}
      {i18n.language === 'en' && <ConsentEn />}
      <BackButton />
    </main>
  )
}

export default TermsAndConditionsFlinksPage
