import { useTranslation } from 'react-i18next'
import { EWorksheetStatus } from '@src/types'
import { useMemo } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import {
  applicantFullName,
  applicantIncomeAccepted,
  areAllDocumentsReadyForCVT,
  hasMissingDocuments,
} from '@src/data/creditapp-selectors'
import CreditStep, { CreditStepStatus } from './CreditStep'
import { contractIsRecerved } from '../../hooks'

type Props = {
  creditApp: FilteredCreditApplication
}

const WorksheetCreditStep = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const applicantIncomeStepDone = applicantIncomeAccepted(creditApp)

  const requiredDocumentsWithStatus = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)

  const { status, content, link } = useMemo<{ status: CreditStepStatus; content?: string; link?: string }>(() => {
    if (hasMissingDocuments(creditApp)) return { status: CreditStepStatus.NotReady }

    if (
      !creditApp.allIncomesConfirmed ||
      !creditApp.allTaskCompletedForCVT ||
      !areAllDocumentsReadyForCVT(requiredDocumentsWithStatus) ||
      !applicantIncomeStepDone
    )
      return { status: CreditStepStatus.Await, content: t('overview.worksheetWaitContract') }

    if (contractIsRecerved(creditApp.contract.status)) return { status: CreditStepStatus.Completed }

    return {
      status: CreditStepStatus.RequiresAttention,
      content: t('overview.worksheetContent'),
      link: creditApp.worksheet?.status === EWorksheetStatus.Draft ? 'worksheet' : 'contract',
    }
  }, [applicantIncomeStepDone, creditApp, requiredDocumentsWithStatus, t])

  return (
    <CreditStep
      status={status}
      linkToDetail={link ?? ''}
      title={t('worksheet.title', { name: applicantFullName(creditApp.applicant) })}
      content={content ?? ''}
      hideLinkToDetail={status !== CreditStepStatus.RequiresAttention}
    />
  )
}

export default WorksheetCreditStep
