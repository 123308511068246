import { Trans, useTranslation } from 'react-i18next'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { FormatCurrency } from '@src/services/Formatter'
import RadioButtonList from '@src/components/RadioButtonList'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { useEffect } from 'react'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import useTermPaymentsTable from '@src/containers/InitialApprobationPage/hooks'
import useTermAmountOptions from '@src/data/credit-application-hooks'
import { Link } from 'react-router-dom'

type Props = {
  soonestDeliveryOn: Date
  creditApp: FilteredCreditApplication
  setStepState: StepStateUpdater
  setNext: () => void
}

const TermStep = ({ soonestDeliveryOn, creditApp, setStepState, setNext }: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext<WorksheetForm>()

  const {
    field: { value, onChange, onBlur, ref: _ref, ...field },
  } = useController({ name: 'term', control })
  const finalAmount = useWatch({ control, name: 'amountRequested' })
  const firstPaymentOn = useWatch({ control, name: 'firstPaymentOn' })

  const [monthlyPaymentPerMonths, isLoadingTerms] = useTermPaymentsTable(creditApp, {
    firstPaymentOn,
    activationDate: soonestDeliveryOn,
    requestedAmount: Number(finalAmount),
  })
  const termOptions = useTermAmountOptions(monthlyPaymentPerMonths)

  useEffect(() => setStepState({ loading: isLoadingTerms }), [isLoadingTerms, setStepState])

  if (isLoadingTerms) return <div className="loader" />

  return (
    <>
      <h3 style={{ marginBottom: '16px' }}>
        <Trans i18nKey="worksheet.term.title" values={{ finalAmount: FormatCurrency(finalAmount) }} />
      </h3>
      <p>{t('worksheet.term.description')}</p>
      <div className="paragraph">
        {termOptions.length && (
          <div className="radiobuttons-stacked-container">
            <RadioButtonList
              {...field}
              onChange={(e) => {
                onChange(parseInt(e.currentTarget.value, 10))
                onBlur()
                setNext()
              }}
              onClick={() => {
                onBlur()
                setNext()
              }}
              defaultValue={value.toString()}
              options={termOptions}
              reverse
            />
          </div>
        )}
        {!termOptions.length && (
          <p style={{ textAlign: 'center' }}>
            <Trans
              i18nKey="worksheet.term.empty"
              values={{
                phoneNumber: '1-888-689-9876',
              }}
              components={{ a: <Link to="tel:18886899876" style={{ textWrap: 'nowrap' }} /> }}
            />
          </p>
        )}
      </div>
    </>
  )
}

export default TermStep
