import { AsyncActionButton } from '@src/components'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BackButton from '@src/components/BackButton'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import {
  areApplicantIncomesUnderReview,
  bankStatementOrVoidCheckAreDeclined,
  hasApplicantCompletedFlinks,
} from '@src/data/creditapp-selectors'
import {
  DeleteBankAccountRequestDto,
  useContinueWithComputedIncome,
  useResetApplicantBankRequest,
} from '@src/api/credit-api'
import { FormatCurrency } from '@src/services/Formatter'
import FlinksPage from '@src/containers/Flinks/FlinksPage'
import SectionTitle from '@src/components/PageSectionTitle'

type Props = {
  creditApp: FilteredCreditApplication
}

const ApplicantIncomePage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const applicant = creditApp.applicant

  const hasApplicantFinishedFlinks = hasApplicantCompletedFlinks(creditApp)

  const bankStatementDeclined = bankStatementOrVoidCheckAreDeclined(creditApp)

  const areIncomePendingReview = areApplicantIncomesUnderReview(creditApp)

  const [resetBankRequest, isResettingBankRequest] = useResetApplicantBankRequest()
  const [continueWithComputedIncomeRequest, isContinueWithComputedIncomeRequest] = useContinueWithComputedIncome()

  const changeBankAccount = async () => {
    const dto: DeleteBankAccountRequestDto = {
      id: creditApp.id,
    }
    await resetBankRequest(dto)
  }

  const continueWithComputedIncome = async () => {
    const dto = {
      id: creditApp.id,
    }
    await continueWithComputedIncomeRequest(dto).then(() => {
      navigate(`/creditapplication/${creditApp.id}`)
    })
  }

  if (applicant.areIncomesConfirmed)
    return (
      <main className="general-message">
        <div className="paragraph full-width-center-container">
          <h4 className="subtitle">{t('common.incomeVerification')}</h4>
          <h3>{t('incomeTile.confirmed')}</h3>
        </div>
        <div className="btn-group centered-one-btn-group full-width-mobile">
          <BackButton />
        </div>
      </main>
    )

  if (areIncomePendingReview)
    return (
      <main className="general-message">
        <div className="paragraph full-width-center-container ">
          <h4 className="subtitle">{t('common.incomeVerification')}</h4>
          <h3>{t('incomeTile.underReview')}</h3>
        </div>
        <div className="btn-group centered-one-btn-group full-width-mobile">
          <BackButton />
        </div>
      </main>
    )

  if (hasApplicantFinishedFlinks && (!applicant.areFlinksIncomeMatchingDeclared || bankStatementDeclined))
    return (
      <main className="general-message" style={{ display: 'block', textAlign: 'center' }}>
        <div className="paragraph">
          <SectionTitle title={t('common.incomeVerification')} />
          <h1>{t('common.oopsTitle')}</h1>

          <p className="black-strong">
            {!bankStatementDeclined && !applicant.areFlinksIncomeMatchingDeclared && (
              <strong>
                <Trans>{t('flinksIncomeRefusalMessages.incomeMismatch')}</Trans>
              </strong>
            )}
          </p>

          {bankStatementDeclined && <Trans>{t('flinksIncomeRefusalMessages.bankStatementsRefused')}</Trans>}

          {!bankStatementDeclined && !applicant.areFlinksIncomeMatchingDeclared && (
            <ul id="applicant-incomes-options">
              {applicant.canContinueWithComputedIncome && (
                <li>
                  <Trans
                    i18nKey="flinksIncomeRefusalMessages.useComputedIncome"
                    values={{ computedIncome: FormatCurrency(applicant.computedIncome) }}
                  />
                </li>
              )}
              {!applicant.areFlinksIncomeMatchingDeclared && (
                <li className="black-strong">
                  <Trans i18nKey="flinksIncomeRefusalMessages.useOtherAccount" />
                </li>
              )}
            </ul>
          )}

          <div className="btn-group mobile-reversed" style={{ marginTop: '3rem' }}>
            <BackButton />
            <AsyncActionButton
              isPending={isResettingBankRequest}
              withConfirm
              primary
              onClick={changeBankAccount}
              className="btn-blue btn-simple"
            >
              {t('flinksIncomeRefusalMessages.useOtherAccountBtn')}
            </AsyncActionButton>

            {applicant.canContinueWithComputedIncome && (
              <AsyncActionButton
                isPending={isContinueWithComputedIncomeRequest}
                withConfirm
                primary
                onClick={continueWithComputedIncome}
                className="btn-blue btn-simple"
              >
                {t('flinksIncomeRefusalMessages.useComputedIncomeBtn')}
              </AsyncActionButton>
            )}
          </div>
        </div>
      </main>
    )

  return <FlinksPage />
}

export default ApplicantIncomePage
