import { useEffect } from 'react'
import { IdentityVerificationSession } from '@src/api/identity-verification-session-api'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useDefaultConfig, useScript } from './vouched-hooks'

type Props = {
  id: string
  session: IdentityVerificationSession
  creditApplication: FilteredCreditApplication
}

const VouchedComponent = ({ id, session, creditApplication }: Props) => {
  const scriptLoaded = useScript('https://static.vouched.id/widget/vouched-2.0.0.js')

  const { config } = useDefaultConfig(creditApplication, session)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (scriptLoaded && window.Vouched) {
      const vouched = window.Vouched({ ...config })
      vouched.mount(`#${id}`)
      return vouched.unmount(`#${id}`)
    }
  }, [scriptLoaded, config, id])

  return <div id={id} style={{ height: '100%' }} />
}

export default VouchedComponent
