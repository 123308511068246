import { IApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights: IApplicationInsights | null = null

export function setupTracking(ai: IApplicationInsights): void {
  appInsights = ai
}

export function reportErrorToConsole(error: unknown) {
  // eslint-disable-next-line no-console
  console.log(error)
}
export function reportErrorToServer(error: Error) {
  reportErrorToConsole(error)
  appInsights?.trackException({ error, severityLevel: 3 })
}

export function reportTraceToServer(message: string) {
  appInsights?.trackTrace({ message })
}
