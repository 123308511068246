import { ExpandableSection } from '@src/components'
import { useTranslation } from 'react-i18next'

const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <main className="step-0">
      <div className="paragraph">
        <h2>
          {t('privacyPolicy.policyTitle')} <i className="fa-light fa-address-card" />
        </h2>
        <h3>{t('privacyPolicy.policySubtitle')}</h3>

        <p>{t('privacyPolicy.policyExplanation')}</p>
        <p>{t('privacyPolicy.policyID')}</p>
        <p>{t('privacyPolicy.vouchedPolicy')}</p>

        <p>{t('privacyPolicy.byProceeding')}</p>
        <ExpandableSection toggleText={t('privacyPolicy.digitalIDTerms')}>
          <div>
            <p>{t('privacyPolicy.termsCompliance')}</p>

            <h4>{t('privacyPolicy.purposeTitle')}</h4>
            <p>{t('privacyPolicy.purposeContent')}</p>

            <h4>{t('privacyPolicy.biometricTitle')}</h4>
            <p>{t('privacyPolicy.biometricContent')}</p>
            <p>{t('privacyPolicy.biometricContent2')}</p>

            <h4>{t('privacyPolicy.securityTitle')}</h4>
            <p>{t('privacyPolicy.securityContent')}</p>
            <p>&#8226; {t('privacyPolicy.firewall')}</p>
            <p>&#8226; {t('privacyPolicy.encryption')}</p>
            <p>&#8226; {t('privacyPolicy.integrity')}</p>

            <h4>{t('privacyPolicy.retentionTitle')}</h4>
            <p>{t('privacyPolicy.retentionContent')}</p>

            <h4>{t('privacyPolicy.communicationTitle')}</h4>
            <p>{t('privacyPolicy.communicationContent')}</p>
            <p>{t('privacyPolicy.communicationContent2')}</p>

            <h4>{t('privacyPolicy.rightsTitle')}</h4>
            <p>{t('privacyPolicy.rightsContent')}</p>

            <p>{t('privacyPolicy.privacyOfficer')}</p>
            <p>501-160 Bloor St. E Toronto, ON, M4W 1B9</p>

            <p>{t('privacyPolicy.addressed')}</p>
          </div>
        </ExpandableSection>
        <p>{t('privacyPolicy.byProceeding2')}</p>

        <p>
          <i className="fa-light fa-lock" /> {t('privacyPolicy.privacyFooter')}
        </p>
        <a className="blue-link" href="https://ifinancecanada.com/privacy-policy/" target="_blank" rel="noreferrer">
          {t('privacyPolicy.privacyPolicy')} <i className="fa-solid fa-arrow-up-right-from-square" />
        </a>
      </div>
    </main>
  )
}

export default PrivacyPolicy
