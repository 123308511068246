import { useMemo } from 'react'
import {
  CreditApplicationDocument,
  RequiredDocument,
  RequiredDocumentWithStatus,
} from '@src/api/types/FilteredCreditApplication'
import { buildRequiredDocumentsWithStatus } from './creditapp-selectors'

export default function useRequiredDocumentsWithStatus(
  requiredDocuments: RequiredDocument[] | undefined | null,
  documents: CreditApplicationDocument[] | undefined | null,
): RequiredDocumentWithStatus[] {
  return useMemo(() => {
    return buildRequiredDocumentsWithStatus(requiredDocuments, documents)
  }, [requiredDocuments, documents])
}
