import { useNeedAssistanceTask } from '@src/api/credit-api'
import SectionTitle from '@src/components/PageSectionTitle'
import { reportErrorToConsole } from '@src/services/error-logger'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const FailedIdentificationPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [needAssistanceTask] = useNeedAssistanceTask()
  const navigate = useNavigate()

  return (
    <main className="step-0">
      <SectionTitle title={t('identification.failed')} />
      <h1>{t('identification.explanation')}</h1>
      <button
        type="button"
        className="btn btn-blue primary-action-btn"
        onClick={() => {
          navigate(`/creditApplication/${id}/vouched`, { replace: true })
        }}
      >
        <span>{t('identification.retryVerification')}</span>
        <i className="fa-regular fa-paper-plane-top" />
      </button>
      <div className="paragraph">
        <p>{t('identification.createNew')}</p>
      </div>
      <button
        type="button"
        className="btn"
        onClick={() => {
          needAssistanceTask({ creditApplicationId: id as string })
            .then(() => navigate(`/creditApplication/needassistance`, { replace: true }))
            .catch(reportErrorToConsole)
        }}
      >
        {t('identification.requestAssistance')}
      </button>
    </main>
  )
}

export default FailedIdentificationPage
