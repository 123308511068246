import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { ECreditApplicationStatus } from '@src/types'
import SectionTitle from '@src/components/PageSectionTitle'

type Props = {
  creditApp: FilteredCreditApplication
}

const ClosedApplicationTile = ({ creditApp }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const creditAppStatus = creditApp.status as ECreditApplicationStatus.Completed | ECreditApplicationStatus.Cancelled

  return (
    <main className="general-message">
      <SectionTitle title={t('common.financeApplication')} />
      {creditAppStatus === ECreditApplicationStatus.Completed && (
        <h1 style={{ marginBottom: '2rem' }}>{t(`common.completedTitle`)}</h1>
      )}
      <h3>
        <p className="black-strong">{t(`common.${creditAppStatus}`)}</p>
      </h3>
      <div className="btn-group full-width-mobile">
        <button
          type="button"
          className="btn btn-blue primary-action-btn"
          onClick={() => {
            navigate('/', { replace: true })
          }}
        >
          <span>{t('common.newApplication')}</span>
          <i className="fa-regular fa-paper-plane-top" />
        </button>
      </div>
    </main>
  )
}

export default ClosedApplicationTile
