import { EPrequalificationDecision } from '@src/types/constants'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import Loader from '@src/components/Loader'
import { useTranslation } from 'react-i18next'
import ApprovedPrequalForm from './Components/ApprovedPrequalForm'
import DeclinedPrequalForm from './Components/DeclinedPrequalForm'
import FailedToObtainCreditReportForm from './Components/FailedToObtainCreditReportForm'

type Props = {
  creditApp: FilteredCreditApplication
}

const PrequalResultPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()

  if (creditApp.prequalificationDecision === null) {
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  }

  const { decision } = creditApp.prequalificationDecision

  return (
    <div>
      {decision === EPrequalificationDecision.Approved && <ApprovedPrequalForm creditApp={creditApp} />}
      {decision === EPrequalificationDecision.Refused && <DeclinedPrequalForm creditApp={creditApp} />}
      {decision === EPrequalificationDecision.Pending && <FailedToObtainCreditReportForm creditApp={creditApp} />}
    </div>
  )
}

export default PrequalResultPage
