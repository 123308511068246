import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { EWorksheetStatus } from '@src/types'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { getWorksheet } from '@src/data/creditapp-selectors'
import { DEFAULT_STEP, EWorksheetStep, EWorksheetStepList } from './hooks'
import WorksheetStepper from './WorksheetStepper'
import { canAccessWorksheet } from './selectors'

interface Props {
  creditApp: FilteredCreditApplication
}

const WorksheetPage = ({ creditApp }: Props) => {
  const { step } = useParams<{ step: EWorksheetStep }>()
  const navigate = useNavigate()
  const worksheet = getWorksheet(creditApp)

  if (!step || !EWorksheetStepList.includes(step)) return <Navigate to={`../worksheet/${DEFAULT_STEP}`} replace />
  if (!canAccessWorksheet(creditApp)) {
    if (creditApp.worksheet?.status !== EWorksheetStatus.Draft) return <Navigate to="../contract" replace />
    return <Navigate to=".." replace />
  }

  return (
    <WorksheetStepper
      creditApp={creditApp}
      setStep={(s) => navigate(`../worksheet/${s}`, { replace: true })}
      step={step}
      worksheet={worksheet}
    />
  )
}

export default WorksheetPage
