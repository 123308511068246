import * as yup from '@src/types/schemas/common/yup-extended'
import yupExtInt from '@src/types/schemas/common/SchemaTypes'
import {
  Constants,
  EGender,
  EGenderList,
  EHomeFeeType,
  EJobType,
  ELanguage,
  ELanguageList,
  EOtherIncomeType,
} from '@src/types/constants'
import { OriginationTracking } from './origination'

const nameRegex = /^([-A-ZÀ-Ü]|[a-zà-ü])+([-A-ZÀ-Ü]|[a-zà-ü]|['.\s])*$/i
const civicNumberRegex = /^([0-9]+)([a-zA-Z])?$/

export const AddressSchema = yup.default.object({
  civicNumber: yup.default
    .string()
    .IsNotEmpty()
    .matches(civicNumberRegex)
    .required('common.errors.required')
    .max(10)
    .default(''),
  street: yup.default.string().IsNotEmpty().required('common.errors.required').max(100).default(''),
  apartment: yup.default.string().max(50),
  suite: yup.default.string().max(10),
  postalCode: yup.default.string().required('common.errors.required').IsValidCanadianPostalCode().default(''),
  city: yup.default.string().IsNotEmpty().required('common.errors.required').max(50).default(''),
  stateIso: yup.default.string().required('common.errors.required').default(''),
  country: yup.default.string().default('Canada'),
  years: yupExtInt.integer.positive().nullable().default(null),
  months: yupExtInt.integer.positive().default(0).required('common.errors.required'),
})

export type Address = yup.default.InferType<typeof AddressSchema>

export const ExpensesSchema = yup.default.object({
  homeFeeTypeId: yup.default.mixed<EHomeFeeType>().required('common.errors.required').default(EHomeFeeType.Rent),
  totalMonthlyHomePayment: yupExtInt.integer
    .min(0)
    .default(0)
    .required('common.errors.required')
    .when('homeFeeTypeId', {
      is: (val: EHomeFeeType) => val === EHomeFeeType.Rent || val === EHomeFeeType.OwnWithMortgage,
      then: (schema) => schema.positive('common.errors.positive').required('common.errors.required'),
      otherwise: (schema) => schema.notRequired().nullable(),
    })
    .default(0),
  rentMonthly: yupExtInt.integer,
  mortgageMonthly: yupExtInt.integer,
  housingMonthly: yupExtInt.integer
    .positive()
    .when('totalMonthlyHomePayment', {
      is: (val: number) => val > 0,
      then: (schema) =>
        schema
          .required()
          .maxReference(
            'totalMonthlyHomePayment',
            'housingMonthly must be less than or equal to totalMonthlyHomePayment',
          )
          .default(0),
      otherwise: (schema) => schema.notRequired(),
    })
    .when('homeFeeTypeId', {
      is: (val: EHomeFeeType) => val === EHomeFeeType.WithParents,
      then: (schema) => schema.min(0).default(0).required('common.errors.required'),
      otherwise: (schema) => schema.notRequired(),
    }),
})

export type Expenses = yup.default.InferType<typeof ExpensesSchema>

export const JobSchema = yup.default.object({
  jobType: yupExtInt.numberEnum<EJobType>().nullable().required('common.errors.required').default(EJobType.fullTime),
  employerName: yup.default
    .string()
    .max(100)
    .when('jobType', {
      is: (val: EJobType) => val === EJobType.Unemployed,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required('common.errors.required').default(''),
    }),
  jobTitle: yup.default.string().when('jobType', {
    is: (val: EJobType) => val === EJobType.Unemployed,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required('common.errors.required').max(50).default(''),
  }),
  annualSalary: yupExtInt.integer.when('jobType', {
    is: (val: EJobType) => val === EJobType.Unemployed,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required('common.errors.required').min(1, 'common.errors.positive').default(null),
  }),
  employerPhone: yup.default.string().when('jobType', {
    is: (val: EJobType) => val === EJobType.Unemployed,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.IsValidCanadianPhone().required('common.errors.required'),
  }),
  employerPhoneExt: yup.default.string().IsValidPhoneExtension().nullable(),
  months: yupExtInt.integer.when('jobType', {
    is: (val: EJobType) => val === EJobType.Unemployed,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.positive().required('common.errors.required'),
  }),
  yearMonths: yupExtInt.integer.nullable(),
  years: yupExtInt.integer.nullable(),
})

export type ApplicantJob = yup.default.InferType<typeof JobSchema>

export const OtherIncomeSchema = yup.default.object({
  jobType: yupExtInt.integer.default(EJobType.Unemployed).required('common.errors.required'),
  typeId: yupExtInt.integer.when('jobType', {
    is: (val: EJobType) => val === EJobType.Unemployed,
    then: (schema) => schema.required('common.errors.required'),
  }),
  annualAmount: yupExtInt.integer.when('typeId', {
    is: (value: EOtherIncomeType | null) => value !== null,
    then: (schema) => schema.required('common.errors.required').min(1),
  }),
  description: yup.default
    .string()
    .nullable()
    .when('typeId', {
      is: (val: EOtherIncomeType) => val === EOtherIncomeType.other,
      then: (schema) => schema.required('common.errors.required'),
    }),
})

export type ApplicantOtherIncome = yup.default.InferType<typeof OtherIncomeSchema>

export const ApplicantSchema = yup.default.object({
  genderId: yupExtInt.numberEnum<EGender>(EGenderList).required('common.errors.required').default(EGender.Male),
  languageId: yupExtInt
    .numberEnum<ELanguage>(ELanguageList)
    .nonNullable()
    .required('common.errors.required')
    .default(ELanguage.French),
  birthDate: yup.default.string().isValidDate().validateAgeRequirement().required('common.errors.required').default(''),
  firstName: yup.default.string().matches(nameRegex).required('common.errors.required').max(50).default(''),
  middleName: yup.default.string().EmptyStringToNull().nullable().matches(nameRegex).max(50),
  lastName: yup.default.string().matches(nameRegex).required('common.errors.required').max(50).default(''),
  sin: yup.default.string().default('').SinType(),
  cellPhone: yup.default.string().IsValidCanadianPhone().required('common.errors.required').default(''),
  homePhone: yup.default.string().IsValidCanadianPhone().nullable(),
  email: yup.default.string().required('common.errors.required').email().max(60).default(''),
  currentJobs: yup.default.array(JobSchema).default([]),
  otherIncomes: yup.default.array(OtherIncomeSchema).default([]),
  currentAddress: AddressSchema.default(AddressSchema.getDefault()),
  expenses: ExpensesSchema.default(ExpensesSchema.getDefault()),
  subscribeNewsletter: yup.default.boolean(),
})

export type Applicant = yup.default.InferType<typeof ApplicantSchema>

export const PrequalificationSchema = yup.default.object({
  applicant: ApplicantSchema.required('common.errors.required'),
  requestedLoanAmount: yupExtInt.double
    .nullable()
    .default(null)
    .isRequiredNumber()
    .min(Constants.MinLoanAmount)
    .max(Constants.MaxLoanAmount),
})

export type Prequalification = yup.default.InferType<typeof PrequalificationSchema> & {
  origination: OriginationTracking | null
}

export const LoanAmountInputSchema = PrequalificationSchema.pick(['requestedLoanAmount'])
export type LoanAmountInput = yup.default.InferType<typeof LoanAmountInputSchema>

const applicantPersonalInformations = ApplicantSchema.pick([
  'firstName',
  'middleName',
  'lastName',
  'genderId',
  'languageId',
  'birthDate',
  'sin',
])

export const PersonalInformationInputSchema = yup.default.object({
  applicant: applicantPersonalInformations,
})

export type PersonalInformationInput = yup.default.InferType<typeof PersonalInformationInputSchema>

export const ContactInformationInputSchema = ApplicantSchema.pick([
  'cellPhone',
  'homePhone',
  'email',
  'subscribeNewsletter',
])

export type ContactInformationInput = yup.default.InferType<typeof ContactInformationInputSchema>

export const HomeInformationInputSchema = ApplicantSchema.pick(['currentAddress', 'expenses'])
export type HomeInformationInput = yup.default.InferType<typeof HomeInformationInputSchema>

export const EmploymentInputSchema = ApplicantSchema.pick(['currentJobs', 'otherIncomes'])

export type EmploymentInput = yup.default.InferType<typeof EmploymentInputSchema>
